// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-hyperphosphatemia-dosing-js": () => import("./../../../src/pages/hyperphosphatemia/dosing.js" /* webpackChunkName: "component---src-pages-hyperphosphatemia-dosing-js" */),
  "component---src-pages-hyperphosphatemia-ferric-citrate-efficacy-js": () => import("./../../../src/pages/hyperphosphatemia/ferric-citrate-efficacy.js" /* webpackChunkName: "component---src-pages-hyperphosphatemia-ferric-citrate-efficacy-js" */),
  "component---src-pages-hyperphosphatemia-ferric-citrate-reactions-js": () => import("./../../../src/pages/hyperphosphatemia/ferric-citrate-reactions.js" /* webpackChunkName: "component---src-pages-hyperphosphatemia-ferric-citrate-reactions-js" */),
  "component---src-pages-hyperphosphatemia-free-trial-js": () => import("./../../../src/pages/hyperphosphatemia/free-trial.js" /* webpackChunkName: "component---src-pages-hyperphosphatemia-free-trial-js" */),
  "component---src-pages-hyperphosphatemia-mechanism-of-action-js": () => import("./../../../src/pages/hyperphosphatemia/mechanism-of-action.js" /* webpackChunkName: "component---src-pages-hyperphosphatemia-mechanism-of-action-js" */),
  "component---src-pages-hyperphosphatemia-patient-access-js": () => import("./../../../src/pages/hyperphosphatemia/patient-access.js" /* webpackChunkName: "component---src-pages-hyperphosphatemia-patient-access-js" */),
  "component---src-pages-hyperphosphatemia-patient-profiles-js": () => import("./../../../src/pages/hyperphosphatemia/patient-profiles.js" /* webpackChunkName: "component---src-pages-hyperphosphatemia-patient-profiles-js" */),
  "component---src-pages-hyperphosphatemia-pharmacodynamics-js": () => import("./../../../src/pages/hyperphosphatemia/pharmacodynamics.js" /* webpackChunkName: "component---src-pages-hyperphosphatemia-pharmacodynamics-js" */),
  "component---src-pages-hyperphosphatemia-resources-js": () => import("./../../../src/pages/hyperphosphatemia/resources.js" /* webpackChunkName: "component---src-pages-hyperphosphatemia-resources-js" */),
  "component---src-pages-hyperphosphatemia-sign-up-js": () => import("./../../../src/pages/hyperphosphatemia/sign-up.js" /* webpackChunkName: "component---src-pages-hyperphosphatemia-sign-up-js" */),
  "component---src-pages-hyperphosphatemia-sitemap-js": () => import("./../../../src/pages/hyperphosphatemia/sitemap.js" /* webpackChunkName: "component---src-pages-hyperphosphatemia-sitemap-js" */),
  "component---src-pages-hyperphosphatemia-thank-you-js": () => import("./../../../src/pages/hyperphosphatemia/thank-you.js" /* webpackChunkName: "component---src-pages-hyperphosphatemia-thank-you-js" */),
  "component---src-pages-hyperphosphatemia-treatment-index-js": () => import("./../../../src/pages/hyperphosphatemia-treatment/index.js" /* webpackChunkName: "component---src-pages-hyperphosphatemia-treatment-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iron-deficiency-anemia-about-auryxia-mechanism-of-action-js": () => import("./../../../src/pages/iron-deficiency-anemia/about-auryxia/mechanism-of-action.js" /* webpackChunkName: "component---src-pages-iron-deficiency-anemia-about-auryxia-mechanism-of-action-js" */),
  "component---src-pages-iron-deficiency-anemia-about-auryxia-patient-profile-js": () => import("./../../../src/pages/iron-deficiency-anemia/about-auryxia/patient-profile.js" /* webpackChunkName: "component---src-pages-iron-deficiency-anemia-about-auryxia-patient-profile-js" */),
  "component---src-pages-iron-deficiency-anemia-about-auryxia-resources-js": () => import("./../../../src/pages/iron-deficiency-anemia/about-auryxia/resources.js" /* webpackChunkName: "component---src-pages-iron-deficiency-anemia-about-auryxia-resources-js" */),
  "component---src-pages-iron-deficiency-anemia-access-js": () => import("./../../../src/pages/iron-deficiency-anemia/access.js" /* webpackChunkName: "component---src-pages-iron-deficiency-anemia-access-js" */),
  "component---src-pages-iron-deficiency-anemia-dosing-js": () => import("./../../../src/pages/iron-deficiency-anemia/dosing.js" /* webpackChunkName: "component---src-pages-iron-deficiency-anemia-dosing-js" */),
  "component---src-pages-iron-deficiency-anemia-efficacy-js": () => import("./../../../src/pages/iron-deficiency-anemia/efficacy.js" /* webpackChunkName: "component---src-pages-iron-deficiency-anemia-efficacy-js" */),
  "component---src-pages-iron-deficiency-anemia-index-js": () => import("./../../../src/pages/iron-deficiency-anemia/index.js" /* webpackChunkName: "component---src-pages-iron-deficiency-anemia-index-js" */),
  "component---src-pages-iron-deficiency-anemia-safety-adverse-reactions-js": () => import("./../../../src/pages/iron-deficiency-anemia/safety/adverse-reactions.js" /* webpackChunkName: "component---src-pages-iron-deficiency-anemia-safety-adverse-reactions-js" */),
  "component---src-pages-iron-deficiency-anemia-safety-pharmacodynamics-js": () => import("./../../../src/pages/iron-deficiency-anemia/safety/pharmacodynamics.js" /* webpackChunkName: "component---src-pages-iron-deficiency-anemia-safety-pharmacodynamics-js" */),
  "component---src-pages-iron-deficiency-anemia-sign-up-js": () => import("./../../../src/pages/iron-deficiency-anemia/sign-up.js" /* webpackChunkName: "component---src-pages-iron-deficiency-anemia-sign-up-js" */),
  "component---src-pages-iron-deficiency-anemia-sitemap-js": () => import("./../../../src/pages/iron-deficiency-anemia/sitemap.js" /* webpackChunkName: "component---src-pages-iron-deficiency-anemia-sitemap-js" */),
  "component---src-pages-iron-deficiency-anemia-thank-you-js": () => import("./../../../src/pages/iron-deficiency-anemia/thank-you.js" /* webpackChunkName: "component---src-pages-iron-deficiency-anemia-thank-you-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

